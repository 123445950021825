import { useState, useEffect, CSSProperties } from "react";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import { Link } from "react-router-dom";
import ThemeMaker from "../../components/thememaker";
import axios from "axios";
import { tokenDecode } from "../../utils/helper";
import { config } from "../../utils/config";
import '../../components/table.css';
import styles from "./style.module.css";
import style from "../../components/Search&Options/Search&Options.module.css";
import CircularProgress from '@mui/material/CircularProgress';
import { Paginated } from "../../components/Paginated";
import styleii from "../transaction/transaction.module.css";
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import { styled } from '@mui/material/styles';
import eye from "../../icons/eye.png";
import moment from "moment";
import { AgChartsReact } from 'ag-charts-react';
import Cookies from 'js-cookie';
import "../../index.css"
import { baseUrlDefiner, NodebaseUrlDefiner } from "../../utils/config";
const { apiEndpoint, nodeapiEndpoint } = config;

var $ = require('jquery');
const CustomStyles = {
    Status: {
        display: 'grid',
        justifyItems: "stretch",
        alignItems: "center",
    } as CSSProperties,
}
const LogisticsOrder = () => {
    const decoded: any = tokenDecode();
    const [isLoading, setIsLoading] = useState(false);
    const [orderList, setOrderList] = useState([]);
    const { data } = decoded;
    let sellerProviderId = data?.seller_data?.provider_id

    const rawData = {
        seller_id: ''
    }

    const [issellerSelected, setissellerselected] = useState(false);
    const { permission: { category, order, product, role, seller, tax, user } } = data;
    const [sellerList, setSellerList] = useState([]);
    const [singleSellerSelect, setsingleSellerSelect] = useState("");

    const current_seller_id = decoded?.data?.user?.seller_id;
    var count = 1;
    const fetchIssuesList = async () => {
        let apiEndpoint = baseUrlDefiner();
        let endpoint;
        if (decoded?.data?.user?.seller_id === 1) {
            endpoint = "getallorder"
        } else {
            endpoint = "getsellerordercompletelist"
        }
        setIsLoading(true);
        try {
            const { data } = await axios({
                url: `${apiEndpoint}/${endpoint}`,
                method: "POST",
                headers: {
                    "x-custom-authorization": `Bearer ${sessionStorage.getItem('us_st_d')}`
                },
                data: {
                    seller_id: decoded?.data?.user?.seller_id,
                }
            });
            console.log("data__", data?.data);
            setOrderList(data?.data ?? null);
            setIsLoading(false)
        } catch (error) {
            console.log("error", error);
            setIsLoading(false);
        }
    }


    // const filterBySearch = (event: any) => {
    //     const querySearch = event.target.value;
    //     let SearchFiltere = issuesList.filter((item: any) => {
    //         return (
    //             item?.issue_id.toLowerCase().includes(querySearch.toLowerCase()) ||
    //             item?.order_id.toLowerCase().includes(querySearch.toLowerCase())
    //         );
    //     });
    //     setSearchList(SearchFiltere);
    // };

    useEffect(() => {
        fetchIssuesList();
        if (current_seller_id == 1) {
        }
    }, []);


    const COLUMNS = [
        {
            Header: "Serial No.",
            accessor: (row: any, count: any) => {
                return <>{count + 1}</>;
            },
            disableFilters: true,
            sticky: "left",
        },
        {
            Header: "Order Id",
            accessor: (item: any) => (item?.order_id ? item?.order_id : null),
            sticky: "left"
        },
        {
            Header: "Customer Name",
            accessor: (item: any) => (item?.name ? item?.name : null),
            sticky: "left",
        },
        {
            Header: "Seller Name",
            accessor: (row: any) => {
                let sellerName = row?.address?.start?.location?.descriptor?.name
                if (sellerName) {
                    return sellerName
                } else if (row?.fulfillments) {
                    const parsedData = JSON.parse(row?.fulfillments);
                    const selletName = parsedData[0]?.start?.location?.descriptor?.name
                    return selletName
                } else {
                    return null
                }
            },
            sticky: "left"
        },
        {
            Header: "Total (In Rs)",
            accessor: (item: any) => (item?.order_total ? item?.order_total : null),
            sticky: "left"
        },

        {
            Header: "Buyerapp name",
            Footer: "Buyerapp name",
            accessor: "bap_id",
            sticky: "left"
        },
        // {
        //     Header: "Order Status",
        //     accessor: (item: any) => (item?.order_state ? item?.order_state : ""),
        //     sticky: "left",
        // },
        {
            Header: "Order Status",
            accessor: (item: any) => (item?.order_state ? item?.order_state : ""),
            sticky: "left",
            Cell: ({ value }: any) => {
                let color = "";
                switch (value) {
                    case "pending":
                        color = "orange";
                        break;
                    case "shipped":
                        color = "blue";
                        break;
                    case "Created":
                        color = "green";
                        break;
                    case "canceled":
                        color = "red";
                        break;
                    default:
                        color = "gray";
                }
                return <span style={{ color }}>{value}</span>;
            },
        },

        {
            Header: "Payment Status",
            accessor: (item: any) => (item?.status ? item?.status : ""),
            sticky: "left",
        },
        {
            Header: "Created Date",
            accessor: (row: any) => {
                if (row?.created_at) {
                    return moment.utc(row?.created_at).format("DD-MM-YYYY  HH:mm:ss")
                } else {
                    return null
                }
            },
            sticky: "left"
        },
        {
            Header: "Action",
            filterable: true,
            sticky: "left",
            Cell: ({ row }: any) => (<Link to={{ pathname: '/ordercreate', state: { order_id: row?.original?.order_id ?? null } }} style={CustomStyles?.Status} className="badge badge-outline-dark btn btn-outline-primary">View</Link>),
        },
    ];

    return (
        <>
            <ThemeMaker>
                <div className="row w-100 m-0 mt-1">
                    <div className="col-md-12 p-2">
                        <div className="card-body p-3 rounded bg-white border" style={{ minHeight: "70vh" }}>
                            <div className="ticket_heading">
                                <p className="genericlabel ps-1 m-0 text-danger">Order List</p></div>
                            <div className="table-responsive table-border">
                                <div className="">
                                    {!isLoading ? <Paginated data={orderList} columns={COLUMNS} /> : <div className="d-flex justify-content-center align-items-center" style={{ minHeight: "60vh" }}><CircularProgress /></div>}
                                </div>
                            </div>
                        </div>
                        {/* <div className={`d-flex  flex-md-row ${styles.main}`} style={{ gap: "10px" }}>
                                <div className={`p-3 rounded bg-white border ${styles.ticketlist} `} >
                                    <div className="ticket_heading">
                                        <p className="genericlabel ps-1 m-0 text-danger">Order List</p></div>                       
                                    <div className="bordered mt-2" style={{ height: "70vh", overflow: "scroll" }}>
                                        {!isLoading ? <Paginated data={orderList} columns={COLUMNS} />
                                            : <div className="d-flex  justify-content-center align-items-center" style={{ height: "100%" }}><CircularProgress /></div>}
                                    </div>
                                </div>
                                <div className={`bg-white rounded shadow border p-3  d-flex flex-column ${styles.countmain}`}>
                                    
                                </div>
                            </div> */}
                    </div>
                </div>
            </ThemeMaker>
        </>
    )
}

export default LogisticsOrder;